import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import EnergyTracerTable from '../../../components/tables';
import PopoverTrigger from '../../../components/PopoverTrigger';
import CHARTCOLORS from '../../../helpers/chartColors';
import { ReportLookup } from '../../Reports/ReportHelpers';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
  iconStyle2: {
    fontSize: '18px',
    color: CHARTCOLORS[1],
    marginLeft: '5px',
    cursor: 'pointer',
    position: 'absolute',
    left: '86px',
    top: '3px',
  },
  iconStyle3: {
    fontSize: '18px',
    color: CHARTCOLORS[1],
    marginLeft: '5px',
    cursor: 'pointer',
  },
};

export default function UserNotificationReportOptions(props) {
  const { activeNotification } = props;

  const tableColumns = [
    {
      dataField: 'reportPrimaryType',
      text: 'Report Primary Type',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          {
            ReportLookup.find((report) => report.type === row.reportPrimaryType)
              .primaryName
          }
        </Col>
      ),
    },
    {
      dataField: 'reportSecondaryType',
      text: 'Report Secondary Type',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          {
            ReportLookup.find(
              (report) => report.type === row.reportPrimaryType
            ).reportInfo.find(
              (report) => report.type === row.reportSecondaryType
            ).name
          }
        </Col>
      ),
    },
    {
      dataField: 'payload',
      text: 'Options',
      style: { paddingTop: '10px' },
      formatter: (cell, row) => (
        <Col>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Report Options'}
            popoverContent={
              <Table>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(row.payload).map((key) => (
                    <tr>
                      <td>{key}</td>
                      <td>{row.payload[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
            trigger={'click'}
            placement='right'
            popoverWidth={400}
          >
            <FontAwesomeIcon style={styles.iconStyle3} icon={faInfoCircle} />
          </PopoverTrigger>
        </Col>
      ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: {},
      formatter: (cell, row) => (
        <Col>
          <button
            className='btn btn-danger'
            onClick={() => {
              //deleteReport(cell);
            }}
          >
            <FontAwesomeIcon
              data-id='delete-org-btn'
              style={{
                fontSize: '20px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={faTrashAlt}
            />
          </button>
        </Col>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col>
          <h5>Reports</h5>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Report Notification Instructions'}
            popoverContent={
              <div>
                To add a report to this list visit the reports page then select
                your options and click the 'Save Options Button'.
              </div>
            }
            trigger={'click'}
            placement='right'
          >
            <FontAwesomeIcon style={styles.iconStyle2} icon={faInfoCircle} />
          </PopoverTrigger>
        </Col>
      </Row>
      <EnergyTracerTable
        data={activeNotification.reports}
        columns={tableColumns}
        keyField={'id'}
      />
    </>
  );
}
