import React, { useCallback, useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildings, faPen } from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import ApiServiceServerless from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import AddSubscriptionOrganizationModal from '../../../components/modals/AddSubscriptionOrganizationModal';
import EditSubscriptionModal from '../../../components/modals/EditSubscriptionModal';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function UserSubscription(props) {
  const {
    activeSubscription,
    userOrganizations,
    setSubscriptions,
    setActiveSubscription,
    showToast,
  } = props;

  const [subscriptionOrganizations, setSubscriptionOrganizations] = useState(
    []
  );
  const [sortedSubscriptionOrganizations, setSortedSubscriptionOrganizations] =
    useState([]);
  const [subscriptionOrganizationIds, setSubscriptionOrganizationIds] =
    useState([]);

  const [showEditSubscriptionModal, setShowEditSubscriptionModal] =
    useState(false);
  const [
    showAddSubscriptionOrganizationModal,
    setShowAddSubscriptionOrganizationModal,
  ] = useState(false);

  useEffect(() => {
    setSubscriptionOrganizations(activeSubscription.organizations);
  }, [activeSubscription]);

  useEffect(() => {
    setSubscriptionOrganizationIds(
      subscriptionOrganizations.map((org) => org.id)
    );
  }, [subscriptionOrganizations]);

  useEffect(() => {
    setSortedSubscriptionOrganizations(
      sortBy(subscriptionOrganizations, ['name'])
    );
  }, [subscriptionOrganizations]);

  const deleteOrganization = useCallback(
    (organizationId) => {
      ApiServiceServerless.delete(
        `/subscriptions/subscription_organization/${activeSubscription.id}/${organizationId}`
      )
        .then(() => {
          setSubscriptionOrganizations((prev) =>
            prev.filter((org) => org.id !== organizationId)
          );
          showToast(
            'success',
            'Success',
            'Organization removed from subscription'
          );
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        });
    },
    [activeSubscription.id, showToast]
  );

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Organization',
      style: { paddingTop: '10px' },
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: {},
      formatter: (cell, row) => (
        <Col>
          <button
            className='btn btn-danger'
            onClick={() => {
              deleteOrganization(cell);
            }}
          >
            <FontAwesomeIcon
              data-id='delete-org-btn'
              style={{
                fontSize: '20px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={faTrashAlt}
            />
          </button>
        </Col>
      ),
    },
  ];

  return (
    <>
      <h4 style={{ marginTop: '0.5em', padding: '0' }}>
        {activeSubscription.name}
      </h4>
      <Row>
        <Col>Subscription Tier: {activeSubscription.subscription_tier}</Col>
        <Col>
          <div
            style={{
              textAlign: 'right',
              marginBottom: '1em',
            }}
          >
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faPen}
              title={'Edit Subscription'}
              onClick={() => setShowEditSubscriptionModal(true)}
            />
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faBuildings}
              title={'Add Organization'}
              onClick={() => setShowAddSubscriptionOrganizationModal(true)}
            />
          </div>
        </Col>
      </Row>

      <EnergyTracerTable
        data={sortedSubscriptionOrganizations}
        columns={tableColumns}
        keyField={'id'}
      />
      <AddSubscriptionOrganizationModal
        show={showAddSubscriptionOrganizationModal}
        onHide={() => {
          setShowAddSubscriptionOrganizationModal(false);
        }}
        subscription_id={activeSubscription.id}
        userOrganizations={userOrganizations}
        subscriptionOrganizationIds={subscriptionOrganizationIds}
        setSubscriptionOrganizations={setSubscriptionOrganizations}
        showToast={showToast}
      />
      <EditSubscriptionModal
        show={showEditSubscriptionModal}
        onHide={() => {
          setShowEditSubscriptionModal(false);
        }}
        activeSubscription={activeSubscription}
        setSubscriptions={setSubscriptions}
        setActiveSubscription={setActiveSubscription}
        showToast={showToast}
      />
    </>
  );
}
