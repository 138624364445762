import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Modal, Row, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import AppContext from '../../../contexts/app-context';
import { ApiServiceServerless } from '../../../xhr_libs';
import AddressWrapper from '../../../helpers/AddressWrapper';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import {
  BuildingTypes,
  BUILDING_BENCHMARK_OPTIONS,
} from '../../../helpers/BuildingTypes';
import CHARTCOLORS from '../../../helpers/chartColors';

const formStyle = {
  textAlign: 'left',
};

const buildingDataDefaults = {
  name: '',
  orgId: '',
  address: {},
  area: '',
  notPhysical: false,
  building_type: null,
  benchmark_type: null,
  reference_id: null,
};

export default function AddBuildingModal(props) {
  const {
    show,
    onHide,
    organization,
    handleSetBuilding,
    setDefaultOrg,
    openBuildingModal,
    setDefaultBuildings,
    openAccountModal,
    addAccountOption,
    setSelectedResource,
    setBuildingInfo,
    setUtilityAccountInfo,
    showToast,
  } = props;
  const { setToasts } = useContext(AppContext);

  const [buildingData, setBuildingData] = useState(buildingDataDefaults);
  const [validated, setValidated] = useState(false);
  const [validAddress, setValidAddress] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navToNewBuilding = (building) => {
    setToasts([]);
    setBuildingInfo({ id: building.id, name: building.name });
    setUtilityAccountInfo({ id: null });
    setSelectedResource('building');
  };

  const addAnotherBuilding = (organization) => {
    setToasts([]);
    setDefaultOrg(organization);
    openBuildingModal();
  };

  const addNewAccount = (building_id) => {
    setToasts([]);
    setDefaultBuildings([building_id]);
    openAccountModal();
  };

  useEffect(() => {
    if (organization)
      setBuildingData((prevData) => ({ ...prevData, orgId: organization.id }));
  }, [organization]);

  const saveBuilding = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.currentTarget;
    if (validAddress && form.checkValidity()) {
      ApiServiceServerless.post(
        `/buildings`,
        {
          name: buildingData.name,
          organization_id: buildingData.orgId,
          address: buildingData.address,
          square_footage: parseInt(buildingData.area.replace(/,/g, '')),
          not_physical: buildingData.notPhysical,
          building_type: buildingData.building_type,
          benchmark_type: buildingData.benchmark_type,
          reference_id: buildingData.reference_id,
        },
        { authorization_id: buildingData.orgId }
      )
        .then((res) => {
          handleSetBuilding(res.data);
          setValidated(false);
          setBuildingData(
            !organization
              ? buildingDataDefaults
              : {
                  ...buildingDataDefaults,
                  orgId: organization.id,
                }
          );
          showToast(
            'success_button',
            'Success',
            <div>
              {`New Building ${buildingData.name} was created.`}
              <br></br>
              {addAccountOption && (
                <Button
                  variant='edit'
                  onClick={() => {
                    addNewAccount(res.data.id);
                  }}
                >
                  {'New Utility Account'}
                </Button>
              )}
              <Button
                variant='edit'
                onClick={() => {
                  navToNewBuilding(res.data);
                }}
              >
                {'View Building Details'}
              </Button>
              <Button
                variant='edit'
                onClick={() => {
                  addAnotherBuilding(res.data.organization);
                }}
              >
                {'Add Another Building'}
              </Button>
            </div>
          );
        })
        .catch((error) => {
          showToast('danger', 'Alert', 'Building Creation Error');
          throw error;
        })
        .finally(() => {
          onHide();
          setValidated(false);
          setValidAddress(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setValidated(true);
    }
  };

  const handleCancel = () => {
    onHide();
    setValidated(false);
    setValidAddress(true);
    setBuildingData(
      !organization
        ? buildingDataDefaults
        : {
            ...buildingDataDefaults,
            orgId: organization.id,
          }
    );
  };

  const saveDisabled = !buildingData.name || !validAddress;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Create a Building</Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={saveBuilding}
        style={formStyle}
      >
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='newBuildingForm.name'>
                <Form.Label>
                  Building name
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  required
                  placeholder=''
                  value={buildingData.name}
                  onChange={(e) =>
                    setBuildingData({
                      ...buildingData,
                      name: e.target.value.replace(';', ''),
                    })
                  }
                  maxLength={100}
                />
              </Form.Group>

              <Form.Group controlId='newBuildingForm.organization'>
                <Form.Label>
                  Organization
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>

                <Form.Control
                  required
                  value={organization.name}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='newBuildingForm.reference_id'>
                <Form.Label>Reference ID</Form.Label>
                <Form.Control
                  placeholder=''
                  onChange={(e) =>
                    setBuildingData({
                      ...buildingData,
                      reference_id: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <AddressWrapper
                address={buildingData.address ? buildingData.address : {}}
                onAddressSelect={(value) =>
                  setBuildingData({
                    ...buildingData,
                    address: value,
                  })
                }
                setValidAddress={setValidAddress}
                notRequired={true}
              />

              <Form.Group controlId='newBuilding.type'>
                <Form.Label>
                  Type{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.eia.gov/consumption/commercial/building-type-definitions.php'
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
                      icon={faInfoCircle}
                    />
                  </a>
                </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    setBuildingData({
                      ...buildingData,
                      building_type: e.target.value,
                    })
                  }
                  value={buildingData.building_type || ''}
                >
                  <option disabled key={'select-type'} value={null}></option>
                  {Object.keys(BuildingTypes).map((data, index) => (
                    <option key={index} value={data}>
                      {BuildingTypes[data]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='newBuilding.usage_profile'>
                <Form.Label>Utility Usage Profile </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    setBuildingData({
                      ...buildingData,
                      benchmark_type: e.target.value,
                    })
                  }
                  value={buildingData.benchmark_type || ''}
                >
                  <option disabled key={'select-type'} value={null}></option>
                  {Object.keys(BUILDING_BENCHMARK_OPTIONS).map(
                    (data, index) => (
                      <option key={index} value={data}>
                        {BUILDING_BENCHMARK_OPTIONS[data]}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='newBuildingForm.areaEntry'>
                <Form.Label>
                  Area (sq. ft.)
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <NumberFormat
                  required
                  customInput={Form.Control}
                  placeholder=''
                  thousandSeparator
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onChange={(e) =>
                    setBuildingData({
                      ...buildingData,
                      area: e.target.value,
                    })
                  }
                  value={buildingData.notPhysical ? '' : buildingData.area}
                  disabled={buildingData.notPhysical}
                />
              </Form.Group>
              <Form.Group controlId='newBuildingForm.noAssociatedArea'>
                <Form.Label>No associated area</Form.Label>
                <Form.Check inline className='ml-3 align-text-bottom'>
                  <Form.Check.Input
                    style={{ height: '1.1rem', width: '1.1rem' }}
                    checked={buildingData.notPhysical}
                    onChange={(e) =>
                      setBuildingData({
                        ...buildingData,
                        notPhysical: e.target.checked,
                      })
                    }
                  />
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            submitDisabled={saveDisabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AddBuildingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  handleSetBuilding: PropTypes.func,
  showToast: PropTypes.func,
  setDefaultOrg: PropTypes.func,
  setDefaultBuildings: PropTypes.func,
  openAccountModal: PropTypes.func,
};

AddBuildingModal.defaultProps = {
  setDefaultOrg: () => {},
  setDefaultBuildings: () => {},
  openAccountModal: () => {},
};
