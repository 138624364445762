import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import ApiServiceServerless from '../../xhr_libs';
import SmallerLoader from '../../components/SmallerLoader';
import MonthSelect from '../../components/MonthSelect2';

const CSV_PAYLOAD_OPTS = {
  headers: {
    Accept: 'application/vnd.openxmlformats-',
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

export default function PlugDownloadModal(props) {
  const { show, onHide, providerAccounts, organizationId } = props;

  const [accountId, setAccountId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const setDefaultDates = useCallback(() => {
    const local_today = dayjs.utc(new Date());
    setEndDate(dayjs.utc(new Date(local_today.year(), local_today.month(), 1)));
    setStartDate(local_today.subtract(1, 'year'));
    setMinDate(dayjs.utc(new Date(2010, 0, 1)));
    setMaxDate(dayjs.utc(new Date(local_today.year() + 1, 11, 31)));
  }, []);

  useEffect(() => {
    if (!minDate) {
      setDefaultDates();
    }
  }, [setDefaultDates, minDate]);

  const handleSetReport = (url, fileName) => {
    const old_link = document.getElementById('report-download-link');
    if (old_link) {
      old_link.remove();
    }
    const link = document.createElement('a');
    link.href = url;
    link.id = 'report-download-link';
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadReport = () => {
    setIsGeneratingReport(true);

    const fileName = 'Breakdown Report.xlsx';

    ApiServiceServerless.post(
      'reports/excel_plug',

      {
        account_id: accountId,
        start_year: dayjs.utc(startDate).year(),
        start_month: dayjs.utc(startDate).month() + 1,
        end_year: dayjs.utc(endDate).year(),
        end_month: dayjs.utc(endDate).month() + 1,
      },
      { ...CSV_PAYLOAD_OPTS, authorization_id: organizationId }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        handleSetReport(url, fileName);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setIsGeneratingReport(false);
        onHide();
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton={!isGeneratingReport}>
        Download Data
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={10}>
            <Form.Group>
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setAccountId(e.target.value)}
                value={accountId}
              >
                <option key={''} value={''} hidden>
                  {''}
                </option>
                {sortBy(providerAccounts, ['accountNumber']).map((account) => (
                  <option key={account['id']} value={account['id']}>
                    {account['accountNumber']}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <MonthSelect
              label='Start Date'
              currentDate={startDate}
              setCurrentDate={setStartDate}
              min_date={minDate}
              max_date={maxDate}
              showPicker={true}
            />
            <MonthSelect
              label='End Date'
              currentDate={endDate}
              setCurrentDate={setEndDate}
              min_date={minDate}
              max_date={maxDate}
              showPicker={true}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => downloadReport()}
          disabled={
            !accountId ||
            isGeneratingReport ||
            dayjs.utc(endDate).diff(dayjs.utc(startDate)) <= 0
          }
        >
          {isGeneratingReport && <SmallerLoader text={'Generating...'} />}
          {!isGeneratingReport && <> Download Report</>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
