import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Modal } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefresh } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function EditSubscriptionModal(props) {
  const {
    show,
    onHide,
    showToast,
    activeSubscription,
    setSubscriptions,
    setActiveSubscription,
  } = props;

  const [name, setName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeSubscription) {
      setName(activeSubscription.name);
    } else {
      setName('');
    }
  }, [activeSubscription]);

  const saveSubscription = () => {
    setIsLoading(true);
    ApiServiceServerless.post(`/subscriptions/${activeSubscription.id}`, {
      subscription_name: name,
    })
      .then((res) => {
        showToast('success', 'Success', 'Subscription Updated');
        setActiveSubscription(res.data);
        setSubscriptions((prev) =>
          prev.map((sub) => {
            if (sub.id !== res.data.id) return sub;
            return res.data;
          })
        );
        forceAuthRefresh();
      })
      .catch(() => {
        showToast('danger', 'Error', 'Update Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = name === null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Edit Subscription</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveSubscription}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
