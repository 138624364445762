import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMeter,
  faBuilding,
  faSyncAlt,
  faPen,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

import EnergyTracerTable from '../../../components/tables';
import { useOrganizationRoles } from '../../../helpers/checkRoles';
import EditBuildingModal from '../../../components/modals/BuildingModals/EditBuildingModal';
import AddUtilityAccountModal from '../../../components/modals/UtilityAccountModals/AddUtilityAccountModal';
import AddBuildingModal from '../../../components/modals/BuildingModals/AddBuildingModal';
import FilterInput from '../../../components/FilterInput';
import { ApiServiceServerless } from '../../../xhr_libs';
import UploadResourceModal from '../../../components/modals/UploadResourceModal';

const styles = {
  buttonDiv: { textAlign: 'right' },
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function OrganizationBuildingTable(props) {
  const {
    organization,
    setOrganization,
    getOrganization,
    userSelectedOrganizationDetails,
    setSelectedResource,
    setBuildingInfo,
    setUtilityAccountInfo,
    OBUpdate,
    handleUpdateTables,
    visible,
    showToast,
  } = props;

  const buildingCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'building',
    'create'
  );
  const buildingUpdateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'building',
    'update'
  );
  const utilityAccountCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'create'
  );

  const [editBuildingId, setEditBuildingId] = useState(0);
  const [editBuilding, setEditBuilding] = useState({ id: 0 });
  const [showEditBuildingModal, setShowEditBuildingModal] = useState(false);

  const [showAddBuildingModal, setShowAddBuildingModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showResourceUploadModal, setShowResourceUploadModal] = useState(false);
  const [defaultBuildings, setDefaultBuildings] = useState();

  const [isSaving, setIsSaving] = useState(false);

  const [lastOBUpdate, setLastOBUpdate] = useState(0);
  const [manualRefresh, setManualRefresh] = useState(0);

  const [buildingFilterValue, setBuildingFilterValue] = useState('');

  const handleEditBuildingSelect = (id) => {
    if (id === 0) {
      setEditBuilding({ id: 0 });
    } else {
      setIsSaving(true);
      ApiServiceServerless.get(`buildings/${id}`, {
        authorization_id: id,
      })
        .then((res) => {
          setEditBuilding({
            ...res.data,
          });
          setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }

    setEditBuildingId(id);
  };

  useEffect(() => {
    if (editBuildingId !== 0) {
      setShowEditBuildingModal(true);
    }
  }, [editBuildingId]);

  const buildingLinkFormatter = (title, isLink, setter) => {
    return (
      <div
        className={isLink ? 'et-link' : ''}
        onClick={() => {
          setter();
        }}
        disabled={!isLink}
      >
        {title}
      </div>
    );
  };

  const tableColumns = [
    {
      dataField: 'building.name',
      text: 'Building',
      formatter: (cell, row) =>
        buildingLinkFormatter(row.name, true, () => {
          setSelectedResource('building');
          setBuildingInfo({ id: row.id, name: row.name });
          setUtilityAccountInfo({ id: null });
        }),
    },
    {
      dataField: 'building.accounts',
      text: 'Utility Accounts',
      formatter: (cell, row) =>
        buildingLinkFormatter(
          row.accounts.length + ' utility accounts',
          true,
          () => {
            setSelectedResource('building');
            setBuildingInfo({ id: row.id, name: row.name });
            setUtilityAccountInfo({ id: null });
          }
        ),
    },
    {
      dataField: 'building.id',
      text: '',
      formatter: (cell, row) => (
        <div style={{ textAlign: 'right' }}>
          {buildingUpdateAccess && (
            <FontAwesomeIcon
              style={{ ...styles.iconStyle, fontSize: '18px' }}
              icon={faPen}
              title={'Edit Building'}
              onClick={() => handleEditBuildingSelect(row.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleSetNewBuilding = (data) => {
    setOrganization((prev) => ({
      ...prev,
      buildings: [...prev.buildings, data],
    }));
    handleUpdateTables(3);
  };

  const handleUpdateBuilding = (data) => {
    setOrganization((prev) => ({
      ...prev,
      buildings: prev.buildings.map((building) => {
        if (building['id'] !== data['id']) return building;
        return {
          ...data,
          siblings: building.siblings,
          accounts: building.accounts,
        };
      }),
    }));
    handleUpdateTables(3);
  };

  const handleUpdateBuildingAccounts = (data) => {
    setOrganization((prev) => ({
      ...prev,
      buildings: prev.buildings.map((building) => {
        if (!data['building_ids'].includes(building['id'])) return building;
        let ret = { ...building };
        ret.accounts = [...ret.accounts, data];
        return ret;
      }),
    }));
    handleUpdateTables(3);
  };

  useEffect(() => {
    if (OBUpdate !== lastOBUpdate && visible) {
      getOrganization();
      setLastOBUpdate(OBUpdate);
    }
  }, [OBUpdate, lastOBUpdate, visible, getOrganization]);

  useEffect(() => {
    if (manualRefresh > 0) {
      getOrganization();
      setManualRefresh(0);
    }
  }, [manualRefresh, getOrganization]);

  return (
    <>
      <Row style={{ marginTop: '-0.5em' }}>
        <Col xs={12}>
          <>
            <div>
              {visible && (
                <Row>
                  <FilterInput
                    label='Filter Building'
                    setFilterValue={setBuildingFilterValue}
                    size={3}
                    organization_id={organization.id}
                    preference_key={'bnf'}
                  />

                  <Col md={9}>
                    <div
                      style={{
                        position: 'absolute',
                        right: '2.5%',
                        bottom: '35%',
                      }}
                    >
                      <FontAwesomeIcon
                        style={styles.iconStyle}
                        icon={faSyncAlt}
                        title={'Refresh Table'}
                        onClick={() => setManualRefresh(1)}
                      />
                      {buildingCreateAccess && (
                        <FontAwesomeIcon
                          style={styles.iconStyle}
                          icon={faBuilding}
                          title={'Add Building'}
                          onClick={() => setShowAddBuildingModal(true)}
                        />
                      )}
                      {utilityAccountCreateAccess && (
                        <FontAwesomeIcon
                          style={styles.iconStyle}
                          icon={faMeter}
                          title={'Add Utility Account'}
                          onClick={() => setShowAccountModal(true)}
                        />
                      )}
                      {buildingCreateAccess && (
                        <FontAwesomeIcon
                          style={styles.iconStyle}
                          icon={faUpload}
                          title={'Resource Upload'}
                          onClick={() => setShowResourceUploadModal(true)}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <EnergyTracerTable
                data={sortBy(
                  organization.buildings.filter((building) =>
                    building.name
                      .toLowerCase()
                      .includes(buildingFilterValue.toLowerCase())
                  ),
                  ['name']
                )}
                columns={tableColumns}
                keyField={'id'}
                enableScroll={true}
                wrapperStyle={'et-table-wrapper-short'}
              />
            </div>

            {!organization &&
              !organization.buildings &&
              !organization.buildings.length > 0 && (
                <div style={{ margin: '30px' }}>
                  {`There are no buildings within the organization. Please create a new building to add utility accounts.`}
                </div>
              )}
          </>
        </Col>
      </Row>

      <EditBuildingModal
        show={showEditBuildingModal}
        onHide={() => {
          setShowEditBuildingModal(false);
          handleEditBuildingSelect(0);
        }}
        building_id={editBuildingId}
        building={editBuilding}
        organization_name={organization.name}
        handleSetBuilding={handleUpdateBuilding}
        handleUpdateTables={(_) => {}}
        isLoading={false}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        showToast={showToast}
      />
      <AddUtilityAccountModal
        organization={organization}
        buildings={organization.buildings}
        defaultBuildings={defaultBuildings}
        setDefaultBuildings={setDefaultBuildings}
        show={showAccountModal}
        onHide={() => {
          setShowAccountModal(false);
          setDefaultBuildings([]);
        }}
        openAccountModal={() => setShowAccountModal(true)}
        handleSetAccount={handleUpdateBuildingAccounts}
        parentIsLoading={false}
        buildingInfo={{}}
        setSelectedResource={setSelectedResource}
        setBuildingInfo={setBuildingInfo}
        setUtilityAccountInfo={setUtilityAccountInfo}
        showToast={showToast}
      />
      <AddBuildingModal
        organization={organization}
        show={showAddBuildingModal}
        onHide={() => setShowAddBuildingModal(false)}
        openBuildingModal={() => {
          setShowAddBuildingModal(true);
        }}
        handleSetBuilding={handleSetNewBuilding}
        setDefaultBuildings={setDefaultBuildings}
        openAccountModal={() => setShowAccountModal(true)}
        addAccountOption={true}
        setSelectedResource={setSelectedResource}
        setBuildingInfo={setBuildingInfo}
        setUtilityAccountInfo={setUtilityAccountInfo}
        showToast={showToast}
      />
      <UploadResourceModal
        show={showResourceUploadModal}
        onHide={() => {
          setShowResourceUploadModal(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        handleUpdateTables={handleUpdateTables}
      />
    </>
  );
}
