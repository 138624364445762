import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Container, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import Helmet from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterList } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';

import UtilitySummary from './UtilitySummary';
import HistoricalData from './HistoricalData';
import CarbonSummary from './CarbonSummary';
import CHARTCOLORS from '../../helpers/chartColors';
import { useTabUrl } from '../../helpers/tracked';
import DashboardDateSelectContainer from './DashboardDateSelectContainer';
import Loader from '../../components/Loader';
import { getUserOrganizationPreference } from '../../helpers/user-preferences';
import BuildingSelectModal from '../../components/modals/BuildingSelectModal';

const PAGE_TITLE = 'Dashboard';

const defaultDashboardData = {
  building_info: {},
  benchmark_missing: [],
  building_totals: [],
  benchmark_data: {},
  organization_usage: {},
  month_array: [],
  ghg_breakdown: {},
};

function Dashboard(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    setDisableOrganizationSelect,
    showToast,
  } = props;

  const [dashboardData, setDashboardData] = useState(defaultDashboardData);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateOption, setDateOption] = useState(1);

  const [activeIds, setActiveIds] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [localBuildingInfo, setLocalBuildingInfo] = useState({});
  const [localBuildingIds, setLocalBuildingIds] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [showBuildingModal, setShowBuildingModal] = useState(false);

  const [activeTab, setActiveTab] = useTabUrl('utility-summary');
  const [activeTabList, setActiveTabList] = useState(['building-date']);

  useEffect(() => {
    setActiveIds([]);
    setActiveKeys([]);
    setLocalBuildingInfo({});
    setLocalBuildingIds([]);
  }, [userSelectedOrganization]);

  useEffect(() => {
    if (dashboardData['building_info']) {
      let temp_building_info = { ...dashboardData['building_info'] };
      let temp_building_ids = [];
      Object.values(temp_building_info).forEach((building) => {
        temp_building_ids.push(building.id);
      });
      setLocalBuildingIds(temp_building_ids);
      setLocalBuildingInfo(temp_building_info);
    } else {
      setLocalBuildingInfo({});
      setLocalBuildingIds([]);
    }
  }, [dashboardData]);

  useEffect(() => {
    let temp_prefered_buildings = [];
    const prefered_buildings = getUserOrganizationPreference(
      'dbs',
      userSelectedOrganization.id
    );
    if (
      prefered_buildings &&
      Array.isArray(prefered_buildings) &&
      prefered_buildings.length > 0
    ) {
      prefered_buildings.forEach((building) => {
        if (localBuildingIds.includes(building)) {
          temp_prefered_buildings.push(building);
        }
      });
      setActiveIds([...temp_prefered_buildings]);
    } else {
      setActiveIds([...localBuildingIds]);
    }
  }, [localBuildingIds, userSelectedOrganization.id]);

  useEffect(() => {
    let new_keys = [];
    activeIds.forEach((id) => {
      if (id in dashboardData['building_info']) {
        new_keys.push(dashboardData['building_info'][id]['name']);
      }
    });
    setActiveKeys(new_keys);
  }, [activeIds, dashboardData]);

  useEffect(() => {
    setDisableOrganizationSelect(isLoading);
  }, [isLoading, setDisableOrganizationSelect]);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  const [activeGHGLicense, setActiveGHGLicense] = useState(false);
  useEffect(() => {
    let tempGHGLicense = false;
    if (userSelectedOrganizationDetails.id) {
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.ghg
      ) {
        tempGHGLicense = true;
      }
    }
    setActiveGHGLicense(tempGHGLicense);
  }, [userSelectedOrganizationDetails]);

  const getDashboardData = useCallback(
    (orgId, endDate, dateOption) => {
      setIsLoading(true);
      ApiServiceServerless.get(
        `/dashboard/${orgId}/${dayjs
          .utc(endDate)
          .format('YYYY-MM-DD')}/${dateOption}`,
        { authorization_id: orgId }
      )
        .then(({ data }) => {
          if (Object.keys(data).length !== 0) {
            setDashboardData(data);
          } else {
            setDashboardData(defaultDashboardData);
          }
        })
        .catch((err) => {
          showToast('danger', 'Error', err);
          throw err;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setIsLoading, showToast]
  );

  useEffect(() => {
    if (userSelectedOrganization.id && endDate) {
      getDashboardData(userSelectedOrganization.id, endDate, dateOption);
    }
  }, [userSelectedOrganization.id, endDate, dateOption, getDashboardData]);

  const getEndDate = useCallback(
    (orgId) => {
      if (endDate) return endDate;
      setIsLoading(true);
      ApiServiceServerless.get(`/dashboard/${orgId}`, {
        authorization_id: orgId,
      })
        .then((res) => {
          let end_date = dayjs.utc();
          if (res.data) {
            end_date = dayjs.utc(res.data);
          }
          let start_date = end_date;
          start_date = start_date
            .month(start_date.month() - 11)
            .startOf('month');

          setEndDate(end_date);
          setStartDate(start_date);
          return end_date;
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    },
    [setIsLoading, endDate]
  );

  useEffect(() => {
    if (!userSelectedOrganization.id) {
      setDashboardData(defaultDashboardData);
      setEndDate();
      setIsLoading(false);
    } else {
      getEndDate(userSelectedOrganization.id);
    }
  }, [userSelectedOrganization.id, getEndDate]);

  return (
    <>
      <Container className='et-container et-main-tab-content'>
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>

        <Row
          style={{
            display: 'flex',
            float: 'right',
            width: '425px',
            marginRight: '-1em',
            marginTop: '0.5em',
          }}
          className='et-dashboard-date-select-cont'
        >
          <DashboardDateSelectContainer
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateOption={dateOption}
            setDateOption={setDateOption}
            isLoading={isLoading}
          />

          <FontAwesomeIcon
            style={{
              fontSize: '22px',
              marginLeft: '0.5em',
              color: CHARTCOLORS[1],
              cursor: 'pointer',
              position: 'relative',
              top: '3px',
              right: '20px',
            }}
            icon={faFilterList}
            id={`report-building-select-popover`}
            onClick={() => setShowBuildingModal(true)}
          />
        </Row>
        <Tabs
          id='dashboard-tabs'
          activeKey={activeTab}
          onSelect={(e) => addNewActiveTab(e)}
          className='et-main-tabs'
        >
          <Tab eventKey='utility-summary' title={'Utility Summary'}>
            {activeTabList.includes('utility-summary') && (
              <>
                {' '}
                {isLoading ? (
                  <Loader />
                ) : (
                  <UtilitySummary
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    open={activeTab === 'utility-summary'}
                    activeIds={activeIds}
                    localBuildingInfo={localBuildingInfo}
                    localBuildingIds={localBuildingIds}
                  />
                )}
              </>
            )}
          </Tab>

          <Tab eventKey='historical-data' title={'Historical Data'}>
            {activeTabList.includes('historical-data') && (
              <>
                {' '}
                {isLoading ? (
                  <Loader />
                ) : (
                  <HistoricalData
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    endDate={endDate}
                    dateOption={dateOption}
                    activeIds={activeIds}
                    activeKeys={activeKeys}
                    localBuildingInfo={localBuildingInfo}
                    localBuildingIds={localBuildingIds}
                    selectedBuildings={activeIds}
                    open={activeTab === 'historical-data'}
                    activeGHGLicense={activeGHGLicense}
                  />
                )}
              </>
            )}
          </Tab>

          <Tab eventKey='carbon-summary' title={'Carbon Summary'}>
            {activeTabList.includes('carbon-summary') && (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <CarbonSummary
                    isLoading={isLoading}
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    endDate={endDate}
                    dateOption={dateOption}
                    activeIds={activeIds}
                    activeKeys={activeKeys}
                    localBuildingInfo={localBuildingInfo}
                    localBuildingIds={localBuildingIds}
                    activeGHGLicense={activeGHGLicense}
                    open={activeTab === 'carbon-summary'}
                  />
                )}
              </>
            )}
          </Tab>
        </Tabs>
      </Container>
      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={localBuildingInfo}
        buildingIds={localBuildingIds}
        selectedBuildings={activeIds}
        setSelectedBuildings={setActiveIds}
        setNonSelectedBuildings={() => {}}
        organization_id={userSelectedOrganization.id}
        preference_key={'dbs'}
      />
    </>
  );
}

export default Dashboard;
