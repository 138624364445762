import React, { useState } from 'react';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faFileSpreadsheet,
  faSave,
} from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';

const styles = {
  row: { marginBottom: '1em', textAlign: 'left' },
  icon: {
    fontSize: '21px',
    lineHeight: 'inherit',
    paddingTop: '2px',
    minHeight: '21px',
    minWidth: '21px',
  },
  buttonCol: { textAlign: 'right' },
};

export default function UploadResourceModal(props) {
  const { show, onHide, showToast, organization_id, handleUpdateTables } =
    props;

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [resetTrigger, setResetTrigger] = useState(0);

  const [uploadType, setUploadType] = useState('building');

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTemplate = () => {
    setIsDownloading(true);
    ApiServiceServerless.get(
      `/resource_upload/${uploadType}/${organization_id}`,
      {
        headers: {
          Accept: 'application/vnd.openxmlformats-',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
        authorization_id: organization_id,
      }
    )
      .then((response) => {
        setIsDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `Resource Template.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setIsDownloading(false);
        showToast('danger', 'Error', 'Error downloading template');
      });
  };

  const getCsv = (e) => {
    let files = e.target.files;
    if (files && files.length > 0) {
      const file = new FormData();
      file.append('file', files[0]);
      file.append('fileName', files[0].name);
      setFile(file);
      setFileName(files[0].name);
    }
  };

  const uploadFile = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `resource_upload/${uploadType}/${organization_id}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        authorization_id: organization_id,
      }
    )
      .then((resp) => {
        const msg = resp.data.successes + ' resource(s) successfully uploaded.';
        showToast('success', 'Success', msg);
        if (resp.data.errors.length > 0) {
          const msg =
            resp.data.errors.length +
            ' resource(s) failed to be processed. Failed row(s) are: ' +
            resp.data.errors.toString();
          showToast('warning', 'Warning', msg);
        }
        handleUpdateTables(0);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        resetUpload();
        setIsLoading(false);
        onHide();
      });
  };

  const resetUpload = () => {
    setFile(null);
    setFileName('');
    setResetTrigger((prev) => prev + 1);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Upload Resources</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control
            as='select'
            onChange={(e) => {
              setUploadType(e.target.value);
              resetUpload();
            }}
            value={uploadType}
          >
            <option key={'building'} value={'building'}>
              Buildings
            </option>
            <option key={'account'} value={'account'}>
              Utility Accounts
            </option>
          </Form.Control>
        </Form.Group>
        <Row style={styles.row}>
          <Col md={8}>1. Download template</Col>
          <Col style={styles.buttonCol}>
            <Button
              variant='primary'
              type='submit'
              disabled={isLoading || isDownloading}
              onClick={downloadTemplate}
            >
              <FontAwesomeIcon style={styles.icon} icon={faDownload} />
            </Button>
          </Col>
        </Row>
        <Row style={styles.row}>
          <Col md={8}>
            <>{'2. '}</>
            {file && fileName
              ? fileName.length <= 40
                ? fileName
                : fileName.slice(0, 40) + '...'
              : 'Select a file'}
          </Col>
          <Col style={styles.buttonCol}>
            {' '}
            <input
              style={{ display: 'none' }}
              id='upload-csv'
              type='file'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel '
              label='Select a csv file'
              onChange={getCsv}
              key={resetTrigger}
            ></input>
            <Button variant='primary'>
              <label
                htmlFor='upload-csv'
                style={{
                  margin: '0px',
                  padding: '0px',
                  cursor: 'inherit',
                }}
              >
                <FontAwesomeIcon style={styles.icon} icon={faFileSpreadsheet} />
              </label>
            </Button>
          </Col>
        </Row>

        <Row style={styles.row}>
          <Col md={8}>
            {' '}
            3. {isLoading ? 'Uploading file...' : 'Upload file'}
          </Col>
          <Col style={styles.buttonCol}>
            <Button
              variant='success'
              disabled={
                (file && fileName ? false : true) || isLoading || isDownloading
              }
              onClick={() => uploadFile()}
            >
              <FontAwesomeIcon style={styles.icon} icon={faSave} />
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
